import { httpGet, httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取巡更列表
export function GET_PATROL_LIST(params: object) {
    return httpPost("/patrol/task/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除巡更
export function DELETE_PATROL(params: object) {
    return httpPost("/patrol/task/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 新增巡更
export function ADD_PATROL(params: object) {
    return httpPost("/patrol/task/add", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取所有巡更单位
export function GET_PATROL_UNIT_LIST(params: object) {
    return httpPost("/patrol/task/units", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取电杆列表
export function GET_PATROL_ASSETSPOLE_LIST(params: object) {
    return httpPost("/patrol/task/asset/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取巡更-电杆列表
export function GET_PATROL_POLE_LIST(params: object) {
    return httpPost("/patrol/task/pole/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取巡更-打卡列表
export function GET_PATROL_CARD_LIST(params: object) {
    return httpPost("/patrol/task/card/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取巡更-循环列表
export function GET_PATROL_LOG_LIST(params: object) {
    return httpPost("/patrol/task/log/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
